<template>
  <div class="pageRoot">
    <Puissance4Module />
  </div>
</template>

<script>
import Puissance4Module from '@/modules/Puissance4'

export default {
  components: {
    Puissance4Module,
  },
}
</script>
